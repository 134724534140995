<template>
    <div id="login">
        <div class="login-header">
            <img src="@/assets/images/EME_logo.svg">
        </div>

        <form class="login-container" v-if="actionParams" @submit.prevent>
            <p class="subtitle">Reset Password</p>
            <p class="login-option-text">For {{ this.userInfo.email }}</p>

            <div class="input-container">
                <input v-model="userInfo.password" placeholder="New Password" :type="showPassword ? 'text' : 'password'"/>
            </div>
            <div class="input-container">
                <input v-model="confirmPassword" placeholder="Confirm password" :type="showPassword ? 'text' : 'password'"/>
                <button class="password-show-button" @click="(showPassword = !showPassword)">{{ showPassword ? "Hide" : "Show"}} password</button>
            </div>

            <p v-if="error" class="login-error">{{ error }}</p>

            <button @click="reset" class="button-round login-button" :class="{disabled: submitDisabled}" :disabled="submitDisabled">Reset</button>
        </form>
                
        <form class="login-container" v-else-if="page === 'login'" @submit.prevent>
            <p class="subtitle">Teacher Login</p>
            <div class="input-container">
                <input v-model="userInfo.email" placeholder="Email"/>
            </div>
            <div class="input-container">
                <input v-model="userInfo.password" placeholder="Password" :type="showPassword ? 'text' : 'password'"/>
                <button class="password-show-button" @click="(showPassword = !showPassword)">{{ showPassword ? "Hide" : "Show"}} password</button>
            </div>

            <p v-if="error" class="login-error">{{ error }}</p>

            <button @click="login" class="button-round login-button" :class="{disabled: submitDisabled}" :disabled="submitDisabled">Login</button>
            <p class="forgot-password" @click="forgotPassword">Forgot password?</p>
        </form>

        <div class="login-container" v-else-if="page === 'forgot'">
            <p class="subtitle">Forgot Your Password?</p>

            <div v-if="!forgotEmailSent">
                <p class="login-option-text">Please enter your email and if you have an account with us you'll recieve instructions on how to reset your password.</p>

                <div class="input-container">
                    <input v-model="userInfo.email" placeholder="Email"/>
                </div>

                <button @click="sendForgotEmail" class="button-round login-button" :class="{disabled: submitDisabled}" :disabled="submitDisabled">Send</button>
            </div>
            <div v-else>
                <p class="login-option-text">Email sent! Please check your inbox and spam folders.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { auth, functions } from '@/firebase'
import 'firebase/auth'

export default {
    props: [
        'next',
        'actionParams'
    ],
    data() {
        return {
            page: "login",
            userInfo: {
                name: '',
                email: '',
                password: '',
            },
            confirmPassword: '',
            error: '',
            forgotEmailSent: false,
            showPassword: false,
            submitDisabled: false
        }
    },
    computed: {
        passwordsDoNotMatch() {
            return (this.confirmPassword !== '') && (this.userInfo.password !== this.confirmPassword);
        }
    },
    methods: {
        changePage(v) {
            this.page = v;
            this.forgotEmailSent = false;
        },
        reset() {
            if (this.userInfo.password != this.confirmPassword) {
                this.error = "Passwords do not match."
            } else {
                this.error = ""

                this.submitDisabled = true;
                auth.confirmPasswordReset(this.actionParams.oobCode, this.userInfo.password).then(() => {
					this.$toast.open("Password changed.");
                    this.userInfo.password = "";
                    this.confirmPassword = "";
                    this.$router.push('/login');
                    this.submitDisabled = false;
                }).catch((error) => {
                    this.submitDisabled = false;
                    this.error = error;
                })
            }
        },
        login() {
            this.submitDisabled = true;

            auth.signInWithEmailAndPassword(this.userInfo.email, this.userInfo.password).then(() => {
                this.gotoNext();
            }).catch((error) => {
                this.submitDisabled = false;

                if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                    this.error = 'Oops. Looks like your email or password is wrong.';
                } else if (error.code === "auth/invalid-email") {
                    this.error = "Your email doesn't look like an email address. Try again";
                }
            })
        },
        gotoNext() {
            console.log(this.next);
            if (this.next) {
                this.$router.push({ name: this.next })
            } else {
                this.$router.push('/');
            }
        },
        forgotPassword() {
            this.changePage('forgot');
        },
        sendForgotEmail() {
            this.submitDisabled = true;

            functions.httpsCallable('resetPassword')({
                email: this.userInfo.email
            }).then(result => {
                console.log(result);
                this.submitDisabled = false;
                this.forgotEmailSent = true;
            }).catch(error => {
                this.submitDisabled = false;
                console.log(error);
            });
        },
        ...mapActions('user', {
            setupUserBinds: 'setupUserBinds'
        })
    },
    mounted() {
        if (this.actionParams) {
            auth.verifyPasswordResetCode(this.actionParams.oobCode).then((email) => {
                this.userInfo.email = email;
            }).catch(() => {
                this.$router.push('/');
            })
        }
    }
}
</script>